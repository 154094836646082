<template>
	<div class="join-team">
		<van-form @submit="onSubmit">
			<div class="join-team-1">
				<van-field
					v-for="(item, index) in info[0]"
					:key="index"
					v-model="item.model"
					:name="item.name"
					:label="item.label"
					:placeholder="item.placeholder"
					input-align="right"
					error-message-align="right"
					:rules="item.rules"
				/>
			</div>

			<div class="mt10">
				<template v-for="(item, index) in info[1]">
					<van-field
						:key="item.key"
						class="red"
						:class="item.class"
						readonly
						required
						:name="item.name"
						:value="item.value.text"
						:label="item.label"
						placeholder="请选择"
						input-align="right"
						error-message-align="right"
						@click="item.showPicker = true"
						right-icon="arrow"
						:rules="[
							{
								required: true,
								message: item.message,
								trigger: 'onChange',
							},
						]"
					/>
					<van-popup v-model="item.showPicker" position="bottom" :key="index">
						<van-area
							v-if="item.class"
							:area-list="item.columns"
							@cancel="item.showPicker = false"
							:columns-num="info[1][1].value ? +info[1][1].value.code : 3"
							@confirm="onConfirm($event, 1, index)"
							:loading="item.loading"
						/>
						<van-picker
							v-else
							show-toolbar
							:columns="item.columns"
							@confirm="onConfirm($event, 1, index)"
							@cancel="item.showPicker = false"
						/>
					</van-popup>
				</template>
			</div>

			<div class="mt10">
				<template v-for="(item, index) in info[2]">
					<van-field
						:key="item.name"
						class="textarea"
						type="textarea"
						maxlength="120"
						required
						:border="false"
						v-model="item.model"
						:name="item.name"
						:label="item.label"
						:placeholder="item.placeholder"
						@input="handleInput($event, 2, index)"
						:rules="[{ required: true, message: '' }]"
					/>
					<div class="wordsTips border" :key="index">
						还可以输入 <span>{{ 120 - item.wordsTips }}</span> 字
					</div>
				</template>
			</div>

			<div class="mt10">
				<van-field
					class="red title10"
					readonly
					required
					:name="info[3][0].name"
					:value="info[3][0].value.text"
					:label="info[3][0].label"
					placeholder="请选择"
					input-align="right"
					error-message-align="right"
					@click="info[3][0].showPicker = true"
					right-icon="arrow"
					:rules="[
						{
							required: true,
							message: info[3][0].message,
							trigger: 'onChange',
						},
					]"
				/>
				<van-popup v-model="info[3][0].showPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="info[3][0].columns"
						@confirm="onConfirm($event, 3, 0)"
						@cancel="info[3][0].showPicker = false"
					/>
				</van-popup>

				<van-field
					v-model="info[3][1].model"
					:name="info[3][1].name"
					:label="info[3][1].label"
					:placeholder="info[3][1].placeholder"
					required
					input-align="right"
					error-message-align="right"
					:rules="[{ required: true, message: '' }]"
				/>
			</div>

			<van-button class="btn" round block type="info" native-type="submit"
				>提交</van-button
			>
		</van-form>
	</div>
</template>

<script>
	import { $post, $get } from "@/utils/request.js";
	import { Form, Field, Popup, Picker, Button, Area } from "vant";
	export default {
		name: "joinTeam",
		components: {
			vanForm: Form,
			vanField: Field,
			vanButton: Button,
			vanPopup: Popup,
			vanPicker: Picker,
			vanArea: Area,
		},
		data() {
			return {
				info: [
					[
						{
							model: "",
							name: "contacter",
							label: "姓名",
							placeholder: "请输入店招名",
							rules: [{ required: true, message: "" }],
						},
						{
							model: "",
							name: "mobile",
							label: "联系电话",
							placeholder: "请输入电话号码",
							rules: [
								{ required: true, message: "" },
								{ pattern: /^1\d{10}$/, message: "请输入正确的手机号" },
							],
						},
					],
					[
						{
							name: "proxy_type",
							value: "",
							label: "您现在的身份",
							showPicker: false,
							message: "请选择身份信息",
							columns: ["创业合伙人", "加盟商", "其他"],
						},
						{
							name: "proxy_level",
							value: "",
							label: "您的合作意向",
							showPicker: false,
							message: "请选择合作意向",
							columns: [
								{ text: "省级代理", code: 1 },
								{ text: "市级代理", code: 2 },
								{ text: "区县级代", code: 3 },
							],
						},
						{
							class: "title8",
							name: "region",
							value: "",
							label: "您希望的合作区域",
							showPicker: false,
							message: "请选择合作区域",
							columns: {},
							loading: true,
						},
					],
					[
						{
							model: "",
							name: "introduce",
							label: "您个人或团队介绍",
							placeholder: "请输入您个人或团队介绍",
							wordsTips: 0,
						},
						{
							model: "",
							name: "advantage",
							label: "您个人或团队优势",
							placeholder: "请输入您个人或团队优势",
							wordsTips: 0,
						},
					],
					[
						{
							name: "budget",
							value: "",
							label: "您个人或团队资金预算",
							showPicker: false,
							message: "请选择个人或团队资金预算",
							columns: [
								"10-20万元",
								"20-50万元",
								"50-200万元",
								"200-500万元",
								"500-1000万元",
								"1000-1500万元",
								"1500-3000万元",
							],
						},
						{
							model: "",
							name: "email",
							label: "邮箱",
							placeholder: "请输入邮箱信息",
						},
					],
				],
			};
		},
		methods: {
			onSubmit(values) {
				delete values.region;
				const { code } = this.info[1][2].value;
				code[0] && (values.province_code = +code[0].code);
				code[1] && (values.city_code = +code[1].code);
				code[2] && (values.county_code = +code[2].code);

				values.proxy_level = this.info[1][1].value.code;

				$post("/home/submitUserAgentApply", values)
					.then(() => {
						this.$toast('提交成功');
						this.$router.back();
					})
					.catch((rej) => {
						const { errors } = rej.response.data;
						this.$toast(errors[Object.keys(errors)[0]][0]);
					});
			},
			handleInput(value, key1, index) {
				const data = this.info[key1][index];
				data.wordsTips = value.length;
			},
			onConfirm(value, key1, index) {
				if (
					key1 === 1 &&
					index === 1 &&
					this.info[key1][index].value.code !== value.code
				) {
					this.info[1][2].value = "";
				}
				const data = this.info[key1][index];
				if (typeof value === "string") {
					value = { text: value };
				} else if (Array.isArray(value)) {
					const text = value.reduce((o, i) => {
						return o ? `${o} - ${i.name}` : i.name;
					}, "");
					value = { code: value, text };
				}
				data.value = value;
				data.showPicker = false;
			},
		},
		created() {
			$get("/home/area/listBlock").then((res) => {
				this.info[1][2].columns = res;
				this.info[1][2].loading = false;
			});
		},
	};
</script>

<style lang="scss" scoped>
	.join-team {
		width: 100%;
		min-height: 100%;
		background: #f2f5fc;
		padding-top: 10px;
		box-sizing: border-box;

		.mt10 {
			margin-top: 10px;
		}

		.btn {
			width: 343px;
			height: 44px;
			font-size: 16px;
			color: #ffffff;
			margin: 30px auto 30px;
			background: #00a63a;
		}

		.wordsTips {
			position: relative;
			padding: 0 16px;
			margin-top: -8px;
			padding-bottom: 16px;
			font-size: 12px;
			color: #d0d0d0;
			line-height: 100%;
			text-align: right;
			background: #fff;

			span {
				color: #ff0000;
			}
		}

		.border:after {
			content: "";
			width: 343px;
			position: absolute;
			margin: auto;
			left: 0;
			right: 0;
			bottom: 0;
			border-bottom: 1px solid #ebedf0;
			transform: scaleY(0.5);
		}
	}
</style>