<template>
  <div class="page">
    <div
      class="already"
      v-if="userInfo.is_share == 1"
    >
      <div></div>
      <div
        class="already-1"
        :style="{
				'background-image': `url(${url.bg})`,
			}"
      >
        <img
          class="already-1-0"
          :src="url.logo"
        />
        <img
          class="already-1-1"
          :src="shareInfo.avatar"
        />
        <div class="already-1-2">{{ shareInfo.realname }}</div>
        <div class="already-1-3">邀请码 {{ shareInfo.share_code }}</div>
        <img
          class="already-1-4"
          :src="shareInfo.share_qrcode"
        />
        <img
          class="already-1-5"
          :src="url.scan"
        />
        <div class="already-1-6">扫一扫邀请</div>
        <div class="already-1-7">一次投入,十年收益</div>
        <img
          class="already-1-8"
          :src="href"
        />
      </div>

      <div class="already-2">
        <!-- @click.once.prevent="1" -->
        <a
          @click.prevent="1"
          :href="href"
          v-bind:download="href ? 'share' : false"
          ref="download"
        >
          <div @click="handleSaveImg">保存图片</div>
        </a>
        <div @click="copy(null, shareInfo.share_url)">复制链接</div>
      </div>

      <canvas
        v-show="1 === 2"
        :width="canvas.width * canvas.multiple"
        :height="canvas.height * canvas.multiple"
        ref="canvas"
      ></canvas>
    </div>

    <!-- 无数据权限 -->
    <div
      class="not"
      v-else
    >
      <!-- <div>无分享权限</div> -->
      <div
        class="not-1"
        :style="{
				'background-image': `url(${require('@/assets/share/notBg.png')})`,
			}"
      >
        <img
          class="not-1-1"
          :src="require('@/assets/share/doll.png')"
        />
        <div class="not-1-2">
          成为林主即可获得专属 邀请码实现财富名利双丰收
        </div>
        <img
          class="not-1-3"
          :src="require('@/assets/other/plosticFreeEarch.png')"
        />
      </div>

      <div
        class="not-2"
        @click="toShop"
      >立即成为林主</div>
    </div>
  </div>
</template>

<script>
import { $get } from "@/utils/request.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      url: {
        bg: require("@/assets/share/posterBg.png"),
        logo: require("@/assets/share/logo.png"),
        scan: require("@/assets/share/scan.png")
      },
      img: {},
      canvas: {
        ctx: null,
        width: 300,
        height: 407,
        multiple: 2
      },
      isLoad: false,

      shareInfo: {}, // 海报数据信息

      href: ""
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getShareUrl();
  },
  methods: {
    // 获取海报信息
    getShareUrl() {
      $get("/home/getShareUrl").then(res => {
        res.avatar && (this.url.avatar = res.avatar);
        res.share_qrcode && (this.url.QRcode = res.share_qrcode);
        this.shareInfo = res;
      });
    },

    handleSaveImg() {
      const { canvas } = this.$refs;
      const ctx = canvas.getContext("2d");
      this.canvas.ctx = ctx;
      const { width, height, multiple } = this.canvas;

      ctx.clearRect(0, 0, width * multiple, height * multiple);

      if (this.isLoad) {
        this.drawCanvas();
      } else {
        const arr = [];

        for (const key in this.url) {
          if (this.img[key]) break;
          if (Object.hasOwnProperty.call(this.url, key)) {
            const item = this.url[key];
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = item;

            arr.push(
              new Promise(res => {
                img.onload = () => {
                  this.img[key] = img;
                  res();
                };
              })
            );
          }
        }

        Promise.all(arr).then(() => {
          this.isLoad = true;
          this.drawCanvas();
        });
      }
    },
    drawCanvas() {
      const { ctx, width, height, multiple } = this.canvas;
      ctx.drawImage(this.img.bg, 0, 0, width * multiple, height * multiple);

      ctx.drawImage(this.img.logo, 0, 0, 125 * multiple, 124 * multiple);

      if (this.img.avatar) {
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = tempCanvas.height = 46 * multiple;
        const tempCtx = tempCanvas.getContext("2d");
        tempCtx.arc(23 * multiple, 23 * multiple, 23, 0, 2 * Math.PI);
        tempCtx.fill();
        tempCtx.globalCompositeOperation = "source-in";
        tempCtx.drawImage(this.img.avatar, 0, 0, 46 * multiple, 46 * multiple);

        ctx.drawImage(
          tempCanvas,
          97 * multiple,
          19 * multiple,
          46 * multiple,
          46 * multiple
        );
      }

      ctx.font = `${16 * multiple}px Arial`;
      ctx.textAlign = "start";
      ctx.textBaseline = "top";

      this.shareInfo.realname &&
        ctx.fillText(
          this.shareInfo.realname,
          154 * multiple,
          (34 + 1) * multiple
        );

      ctx.textAlign = "center";
      ctx.fillText(
        `邀请码 ${this.shareInfo.share_code || ""}`,
        150 * multiple,
        (100 + 1) * multiple
      );

      this.img.QRcode &&
        ctx.drawImage(
          this.img.QRcode,
          62.5 * multiple,
          125 * multiple,
          175 * multiple,
          175 * multiple
        );

      ctx.drawImage(
        this.img.scan,
        55 * multiple,
        322 * multiple,
        30 * multiple,
        30 * multiple
      );

      ctx.textAlign = "start";
      ctx.fillText("扫一扫邀请", 101 * multiple, (319 + 1) * multiple);
      ctx.fillText("了解竹林分包", 101 * multiple, (345 + 1) * multiple);

      this.href = this.$refs.canvas.toDataURL();
      this.$toast("长按保存图片");
    },
    copy(id, attr) {
      console.log(id, attr);
      let target = null;

      if (attr) {
        target = document.createElement("div");
        target.id = "tempTarget";
        target.style.opacity = "0";

        if (id) {
          let curNode = document.querySelector("#" + id);
          target.innerText = curNode[attr];
        } else {
          target.innerText = attr;
        }

        document.body.appendChild(target);
      } else {
        target = document.querySelector("#" + id);
      }

      try {
        let range = document.createRange();
        range.selectNode(target);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        this.$toast("链接复制成功");
      } catch (e) {
        this.$toast("链接复制失败，请联系管理员");
      }

      if (attr) {
        // remove temp target
        target.parentElement.removeChild(target);

        target = null;
      }
    },
    // 跳转商品列表
    toShop() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>

<style lang="scss" scoped>
.already {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &-1 {
    width: 300px;
    height: 407px;
    background-size: 100% 100%;
    position: relative;

    img {
      position: absolute;
    }

    div {
      position: absolute;
      line-height: 100%;
      font-size: 16px;
    }

    &-0 {
      width: 125px;
    }

    &-1 {
      width: 46px;
      height: 46px;
      top: 19px;
      left: 97px;
      border-radius: 50%;
    }

    &-2 {
      top: 34px;
      left: 154px;
    }

    &-3 {
      text-align: center;
      width: 100%;
      top: 100px;
    }

    &-4 {
      width: 175px;
      height: 175px;
      margin: auto;
      top: 125px;
      left: 0;
      right: 0;
    }

    &-5 {
      width: 30px;
      top: 322px;
      left: 55px;
    }

    &-6 {
      left: 101px;
      top: 319px;
    }

    &-7 {
      left: 101px;
      top: 345px;
    }

    &-8 {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    canvas {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &-2 {
    height: 44px;
    padding: 0 38px;
    display: flex;

    div {
      width: 140px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      text-align: center;
      color: #00a63a;
      border-radius: 109px;
      background: #ffffff;
    }

    a {
      margin-right: 20px;
    }
  }
}

.not {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &-1 {
    width: 375px;
    height: 349px;
    box-sizing: border-box;
    background-size: 100% 100%;
    font-size: 0;
    padding-top: 27px;
    text-align: center;

    &-1 {
      width: 140px;
    }

    &-2 {
      width: 256px;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin: 4px auto 0;
    }

    &-3 {
      width: 162px;
      margin-top: 50px;
    }
  }

  &-2 {
    width: 345px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    color: #00a63a;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 109px;
  }
}
</style>