<template>
  <div class="dividend-wrap">
    <div class="dividend">
      <div class="info-wrap">
        <span>可用分红券数</span>
        <span>|</span>
        <span>{{fhq}}</span>
      </div>
      <div class="detail-btn" @click="toLink">查看详情</div>
    </div>
  </div>
</template>

<script>
import { Cell } from "vant";
import { $get } from "@/utils/request.js";
export default {
  components: {
    vanCell: Cell,
  },
  data() {
    return {
      // userStockInfo: {},
      fhq: 0.00
    }
  },
  created() {
    this.getUserStockInfo()
  },
  methods: {
    // 分红券信息
    getUserStockInfo() {
      $get("/home/user/fhq").then((data) => {
        console.log(data)
        this.fhq = data.fhq
      })
      // $get("/home/getUserStockInfo").then((res) => {
      //   this.userStockInfo = res
      // })
	  },
    // 跳转
    toLink(item) {
      this.$router.push({ name: 'dividendDetail' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .dividend-wrap {
    background: #F6F7FB;
    height: calc(100% - 16px);
    text-align: center;
    padding-top: 16px;
  }
  .dividend {
    background-image: url(../../../assets/dividend/bg.png);
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    width: 351px;
    height: 114px;
  }
  .info-wrap {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    color: white;
    /* color: #F5CF78;
    background: linear-gradient(180deg, #ffffff 16%, #F5CF78 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  .detail-btn {
    color: #F5BD01;
    font-size: 12px;
    width: 64px;
    line-height: 21px;
    border-radius: 21px;
    border: 1px solid #F5BD01;
    margin-right: 36px;
    float: right;
    margin-top: 20px;
  }
</style>