import Agreement from '@/views/agreement'
import AuthenticationEnterprise from '@/views/authentication/Enterprise'
import AuthenticationIdentity from '@/views/authentication/Identity'
import AuthenticationSuccess from '@/views/authentication/Success'
import Autograph from '@/views/autograph'
import CashOut from '@/views/cashOut/index' // 提现
import Contract from '@/views/contract/index'
import Ofstock from '@/views/ofstock/index'
import DeliverySigning from '@/views/deliverySigning'
import EquipmentRevenue from '@/views/detail/equipmentRevenue'
import Detail from '@/views/detail/index'
import DetailProfit from '@/views/detail/profit'
import DetailShop from '@/views/detail/shop'
import DetailWithdrawal from '@/views/detail/withdrawal'
import DividendDetail from '@/views/dividend/detail'
import Dividend from '@/views/dividend/index'
import Product from '@/views/product/index'
import ProductDetail from '@/views/product/productDetail/index'
import EliteRanking from '@/views/eliteRanking'
import Home from '@/views/home'
import Index from '@/views/home/home'
import My from '@/views/home/my'
import Shop from '@/views/home/shop'
import Team from '@/views/home/team'

import Information from '@/views/information/index'
import InformationRevise from '@/views/information/revise'
import InformationTeam from '@/views/information/team'
import JoinTeam from '@/views/joinTeam'
import Login from '@/views/login/index' // 登录注册
import MyEquipment from '@/views/myEquipment'
import MyEquipmentCardDetail from '@/views/myEquipmentCardDetail'
import MyOrder from '@/views/myOrder'
import MyStore from '@/views/myStore'
import OrderConfirm from '@/views/order/confirm'
import OrderDetail from '@/views/order/detail'
import Paving from '@/views/paving'
import Share from '@/views/share'
import ShareAlready from '@/views/share/already'
import ShareNot from '@/views/share/not'
import OrganizationTeam from '@/views/team/organization'
import SellTeam from '@/views/team/sell'
import PropertyParticulars from '@/views/wallet/propertyParticulars'
import Wallet from '@/views/wallet/index'
import Withdraw from '@/views/wallet/withdraw'
import WithdrawParticulars from '@/views/wallet/withdrawParticulars'
import BankCard from '@/views/wallet/bankCard'
import Conversion from '@/views/home/conversion'
import MyTeam from '@/views/home/my/team/index'
import Performance from '@/views/home/my/performance/index'
import PerformanceDetail from '@/views/home/my/performanceDetail/index'
import PrizePool from '@/views/prizePool/index'
import PrizeDetail from '@/views/prizePool/prizeDetail/index'
import ServiceStaff from '@/views/serviceStaff/index'
import MyBankCard from '@/views/wallet/myBankCard'
import BindingCard from '@/views/wallet/bindingCard'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/home/index'
	}, {
		path: '/home',
		component: Home,
		redirect: '/home/index',
		children: [{
				path: 'index',
				name: 'home',
				component: Index,
				meta: {
					notLoad: true
				}
			}, {
				path: 'conversion',
				name: 'conversion',
				component: Conversion,
			}, {
				path: 'shop',
				name: 'shop',
				component: Shop,
			}, {
				path: 'team',
				name: 'team',
				component: Team,
			}, {
				path: 'my',
				name: 'my',
				component: My,
			},
			{
				path: 'myTeam',
				name: 'myTeam',
				component: MyTeam
			},
			{
				path: 'performance',
				name: 'performance',
				component: Performance
			},
			{
				path: 'performanceDetail',
				name: 'performanceDetail',
				component: PerformanceDetail
			}
		]
	},
	{
		path: '/detail/index/:id',
		name: 'detail',
		component: Detail,
		meta: {
			notLoad: true
		}
	}, {
		path: '/detail/shop/:id',
		name: 'detailShop',
		component: DetailShop,
	}, {
		path: '/detail/profit',
		name: 'detailProfit',
		component: DetailProfit,
	}, {
		path: '/detail/withdrawal',
		name: 'detailWithdrawal',
		component: DetailWithdrawal,
	}, {
		path: '/detail/equipmentRevenue',
		name: 'equipmentRevenue',
		component: EquipmentRevenue,
	}, {
		path: '/authentication/identity',
		name: 'authenticationIdentity',
		component: AuthenticationIdentity,
	}, {
		path: '/authentication/enterprise',
		name: 'authenticationEnterprise',
		component: AuthenticationEnterprise,
	}, {
		path: '/authentication/success',
		name: 'authenticationSuccess',
		component: AuthenticationSuccess,
	}, {
		path: '/autograph',
		name: 'autograph',
		component: Autograph,
	}, {
		path: '/agreement',
		name: 'agreement',
		component: Agreement,
	}, {
		path: '/share',
		component: Share,
		children: [{
			path: 'already',
			name: 'shareAlready',
			component: ShareAlready,
		}, {
			path: 'not', // 废弃 - 合并到了shareAlready页面中
			name: 'shareNot',
			component: ShareNot,
		}]
	}, {
		path: '/eliteRanking',
		name: 'eliteRanking',
		component: EliteRanking,
	}, {
		path: '/myStore/:page',
		name: 'myStore',
		component: MyStore,
	}, {
		path: '/myEquipment',
		name: 'myEquipment',
		component: MyEquipment,
	}, {
		path: '/myEquipmentCardDetail',
		name: 'myEquipmentCardDetail',
		component: MyEquipmentCardDetail,
	}, {
		path: '/joinTeam',
		name: 'joinTeam',
		component: JoinTeam,
	},
	{
		path: '/team/organization',
		name: 'organizationTeam',
		component: OrganizationTeam,
	}, {
		path: '/team/sell',
		name: 'sellTeam',
		component: SellTeam,
	}, {
		path: '/login',
		name: 'login',
		component: Login,
	}, {
		path: '/information/index',
		name: 'information',
		component: Information,
	}, {
		path: '/information/revise',
		name: 'informationRevise',
		component: InformationRevise,
	}, {
		path: '/information/team',
		name: 'informationTeam',
		component: InformationTeam,
	}, {
		path: '/myOrder/:page',
		name: 'myOrder',
		component: MyOrder,
	}, {
		path: '/contract/index',
		name: 'contract',
		component: Contract,
	}, {
		path: '/ofstock/index',
		name: 'ofstock',
		component: Ofstock,
	}, {
		path: '/wallet/index',
		name: 'wallet',
		component: Wallet,
	},
	{
		path: '/wallet/propertyParticulars',
		name: 'propertyParticulars',
		component: PropertyParticulars,
	}, {
		path: '/wallet/withdraw',
		name: 'withdraw',
		component: Withdraw,
	},
	{
		path: '/wallet/withdrawParticulars',
		name: 'withdrawParticulars',
		component: WithdrawParticulars,
	}, {
		path: '/wallet/bankCard',
		name: 'bankCard',
		component: BankCard,
	},
	{
		path: '/product/index',
		name: 'product',
		component: Product,
	}, {
		path: '/productDetail/index',
		name: 'productDetail',
		component: ProductDetail,
	}, {
		path: '/dividend/index',
		name: 'dividend',
		component: Dividend,
	}, {
		path: '/dividend/detail',
		name: 'dividendDetail',
		component: DividendDetail,
	}, {
		path: '/paving/:page',
		name: 'paving',
		component: Paving,
	}, {
		path: '/order/detail',
		name: 'orderDetail',
		component: OrderDetail,
	}, {
		path: '/order/confirm/:id',
		name: 'orderConfirm',
		component: OrderConfirm,
	}, {
		path: '/deliverySigning/:id',
		name: 'deliverySigning',
		component: DeliverySigning,
	}, {
		path: '/cashOut',
		name: 'cashOut',
		component: CashOut,
	},
	{
		path: '/prizePool/index',
		name: 'prizePool',
		component: PrizePool,
	},
	{
		path: '/prizePool/prizeDetail',
		name: 'prizeDetail',
		component: PrizeDetail,
	},
	{
		path: '/serviceStaff/index',
		name: 'serviceStaff',
		component: ServiceStaff
	},
	{
		path: '/wallet/myBankCard',
		name: 'myBankCard',
		component: MyBankCard,
	},
	{
		path: '/wallet/bindingCard',
		name: 'bindingCard',
		component: BindingCard,
	},

]

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
})

// 页面打开前判断是否存在token， 不存在直接跳转登录页
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token') || ''

	if (!to.matched.some(item => item.meta.notLoad) && (!token || !/\S/.test(token))) {
		if (to.name === 'login') {
			next()
		} else {
			next({
				name: 'login'
			})
		}
	} else {
		if (to.name === 'login') {
			next({
				path: '/'
			})
		} else {
			next()
		}
	}
})

export default router
