<template>
	<div class="information-team">
		<UpLoad
			url="/home/editUserGroupAvatar"
			parameter="group_avatar"
			@finish="handleFinish"
		>
			<van-cell
				class="information-team-1"
				is-link
				value="修改头像"
				:clickable="false"
			>
				<template #title>
					<img :src="userGroupData.group_avatar" />
				</template>
			</van-cell>
		</UpLoad>

		<van-cell
			class="information-team-2"
			is-link
			title="昵称"
			:value="
				userGroupData.group_user_data && userGroupData.group_user_data.realname
			"
			:to="{
				name: 'informationRevise',
				params: {
					data: userGroupData.group_user_data.realname,
					url: '/home/editUserGroupName',
					key: 'group_name',
					method: group_nameMehtod,
				},
			}"
		/>

		<van-cell
			class="information-team-2"
			is-link
			title="签名"
			:value="userGroupData.group_sign"
			:to="{
				name: 'informationRevise',
				params: {
					data: userGroupData.group_sign,
					url: '/home/editUserGroupSign',
					key: 'group_sign',
					method: group_signMehtod,
				},
			}"
		/>

		<van-cell
			class="information-team-2 red"
			is-link
			:border="false"
			title="实名"
			value="还未实名，前往认证"
			v-if="!userInfo.icheck"
			:to="{ name: 'authenticationIdentity' }"
		/>
	</div>
</template>

<script>
	import { Cell } from "vant";
	import { mapState, mapMutations } from "vuex";
	import UpLoad from "@/components/UpLoad.vue";
	export default {
		name: "informationTeam",
		components: {
			vanCell: Cell,
			UpLoad,
		},
		computed: {
			...mapState(["userGroupData"]),
		},
		methods: {
			...mapMutations(["setUserGroupData"]),
			group_nameMehtod(val) {
				const data = { ...this.userGroupData };
				data.group_user_data.realname = val;
				this.setUserGroupData(data);
			},
			group_signMehtod(val) {
				this.setUserGroupData({
					...this.userGroupData,
					group_sign: val,
				});
			},
			handleFinish(src) {
				this.setUserGroupData({
					...this.userGroupData,
					group_avatar: src,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.information-team {
		min-height: 100%;
		background: #f2f5fc;

		.van-cell__title,
		.van-cell__value {
			font-size: 16px;
		}

		.van-cell__title {
			color: #000000;
		}

		&-1 {
			width: 375px;
			box-sizing: border-box;
			padding: 12px 12px 12px 15px;

			.van-cell__value,
			.van-icon {
				line-height: 60px;
			}

			.van-cell__title {
				height: 60px;

				img {
					width: 60px;
					height: 60px;
					border-radius: 50%;
				}
			}
		}

		&-2 {
			padding: 12px 12px 12px 15px;
		}

		&-3 {
			padding: 12px 15px;

			.van-cell__value {
				color: #000000;
			}
		}

		.red {
			.van-cell__value {
				color: #00a63a;
			}
		}
	}
</style>