<template>
  <div
    class="detail"
    ref="detail"
  >
    <van-swipe
      class="detail-1"
      :autoplay="30000"
      indicator-color="white"
      @change="handleChange"
    >
      <van-swipe-item
        v-for="(item, index) in detail.banner_img"
        :key="index"
        :style="{
					'background-image': `url(${item})`,
				}"
      ></van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">
          <div
            v-for="(item, index) in detail.banner_img"
            :key="index"
            :class="index === current && 'active'"
            :style="
							index === current &&
							index === 0 && {
								'background-image': `url(${require('@/assets/detail/indicator.png')})`,
							}
						"
          ></div>
        </div>
      </template>
    </van-swipe>

    <div class="detail-2">
      <div>{{ detail.name }}</div>
      <div>￥{{ detail.price }}</div>
    </div>

    <div class="detail-3">
      <Cell :info="{
					title: '描述',
					value: detail.remark,
				}" />
    </div>

    <div
      class="detail-4"
      v-html="detail.desc"
    ></div>

    <div class="detail-5">—— 已经到底了——</div>

    <div
      class="purchase"
      v-if="detail.id"
    >
      <!-- <router-link
				:to="{ name: 'orderConfirm', params: { id: detail.id } }"
				custom
				v-slot="{ navigate }"
			> -->
      <div @click="handleBuy">立即购买</div>
      <!-- </router-link> -->
    </div>

    <Scroll2Top class="top" />
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import Cell from "./Cell.vue";
import Scroll2Top from "@/components/Scroll2Top.vue";
import { $get } from "@/utils/request.js";
import { mapState } from "vuex";
export default {
  name: "detail",
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
    Cell,
    Scroll2Top
  },
  computed: {
    ...mapState(["userInfo"])
  },
  data() {
    return {
      current: 0,
      detail: {}
    };
  },
  created() {
    const { id } = this.$route.params;
    $get(`/home/product/detail/${id}`).then(res => {
      this.detail = res;
    });
  },
  methods: {
    handleChange(index) {
      this.current = index;
    },

    // 立即购买
    handleBuy() {
      this.$router.push({ name: 'orderConfirm', params: { id: this.detail.id } })
      return
      if (this.userInfo.icheck) {
        // 已认证 - 跳转下单页
        this.$router.push({ name: 'orderConfirm', params: { id: this.detail.id } })
      } else {
        // 未认证 - 提示
        if (this.userInfo.user_type) {
          // 企业
          this.$toast('企业认证完成后才可下单哦~')
        } else {
          // 用户
          this.$toast('实名认证完成后才可下单哦~')
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  min-height: 100%;
  background-color: #f2f5fc;
  padding-bottom: 70px;

  &-2,
  &-3 {
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  &-1 {
    width: 375px;
    height: 375px;

    .van-swipe-item {
      background-color: #f2f5fc;
      background-size: cover;
      background-position: center 10%;
    }

    .custom-indicator {
      position: absolute;
      width: 100%;
      height: 14px;
      bottom: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 8px;
        height: 8px;
        background: #ffffff;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        margin-right: 10px;
        background-size: 100% 100%;
      }

      .active {
        background-size: 180%;
        background-position: center;
        background-color: #11ab46;
      }

      div:nth-last-of-type(1) {
        margin: 0;
      }
    }
  }

  &-2 {
    padding: 19px 16px 22px;

    div {
      line-height: 100%;
    }

    div:nth-of-type(1) {
      font-size: 16px;
      color: #333333;
    }

    div:nth-of-type(2) {
      margin-top: 23px;
      font-size: 22px;
      color: #00a63a;
    }
  }

  &-4 {
    background-color: #ffffff;
  }

  &-5 {
    padding: 16px 0;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: #999999;
  }

  .purchase {
    position: fixed;
    bottom: 0;
    padding: 13px 16px;
    background: #ffffff;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);

    div {
      width: 343px;
      height: 44px;
      line-height: 44px;
      background: #00a63a;
      border-radius: 86px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }
  }

  .top {
    position: fixed;
    right: 16px;
    bottom: 205px;
  }
}
</style>