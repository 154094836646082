<template>
  <div class="container">
    <router-view></router-view>
    <TabBar />
  </div>
</template>

<script>
import TabBar from "@/components/TabBar";
import { $get } from "@/utils/request.js";
import { mapMutations } from "vuex";
export default {
  components: {
    TabBar
  },
  created() {
			if (this.token) {
				this.getUserInfo();
			}
		},
		computed: {
			token() {
				return localStorage.getItem("token");
			},
		},
		watch: {
			token(str) {
				if (str) {
					this.getUserInfo();
				}
			},
		},
		methods: {
			...mapMutations(["setUserInfo"]),

			// 用户信息接口
			getUserInfo() {
				$get("/home/getUserInfo").then((res) => {
					this.setUserInfo(res);
				});
			},
		},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: calc(100% - 55px);
}
</style>