<template>
  <div class="detail-profit">
    <div class="header" ref="header">
      <div class="detail-profit-1" @click="showPicker = true">
        <span>{{ columnsSel.name || '选择交易类型' }}</span>
        <img :src="require('@/assets/other/down.png')" />
      </div>
      <div class="detail-profit-2">
        <div class="detail-profit-2-1" @click="datetimeBol = true">
          <div>{{ month.replace("-", "年") }}月</div>
          <div></div>
        </div>
        <div class="detail-profit-2-2">
          <div>本月收益 : {{ flogLogStatics.month_amount }}</div>
          <div>今日收益 : {{ flogLogStatics.today_amount }}</div>
        </div>
      </div>
    </div>

    <div
      class="content"
      :style="`height: calc(100vh - ${headerHeight}px)`"
      @scroll="handleScroll"
    >
      <div
        class="detail-profit-3"
        v-for="(item, index) in flogLogInfo"
        :key="index"
      >
        <div class="detail-profit-3-1">
          <div>{{ item.action_name }}</div>
          <div>{{ item.created_at_str }}</div>
        </div>
        <div class="detail-profit-3-2">{{ item.money }}</div>
      </div>
    </div>

    <!-- 交易类型选择 -->
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :default-index="columnsIndex"
        value-key="name"
        title="交易类型选择"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>

    <!-- 年月选择 -->
    <van-popup
      v-model="datetimeBol"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :max-date="maxDate"
        @confirm="datetimeConfirm"
        @cancel="datetimeCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { $get } from "@/utils/request.js";
import { formatDate } from "@/utils/util.js";
import { Popup, Picker, DatetimePicker } from "vant";
export default {
  name: "detailProfit",
  components: {
    vanPopup: Popup,
    vanPicker: Picker,
    vanDatetimePicker: DatetimePicker,
  },
  data() {
    return {
      showPicker: false,
      columns: [
				{ name: '销售收益', type: 10 },
				{ name: '居间收益', type: 11 },
				{ name: '归属地加盟商收益', type: 12 },
				{ name: '团队收益', type: 13 },
				{ name: '机主收益', type: 14 },
				// { name: "屏主上级收益", type: 15 },
				// { name: "屏主上上级收益", type: 16 },
				{ name: '商家收益', type: 17 },
				{ name: '拓展收益', type: 18 },
				{ name: '公益收益', type: 20 },
				{ name: '铺装地加盟商收益', type: 21 },
				{ name: '战略管道收益', type: 22 },
				{ name: '商务管道收益', type: 23 },
				{ name: '合伙人收益', type: 24 }
      ],
	  columnsIndex: 0, // 交易类型下标
	  columnsSel: {}, // 交易类型选中

      datetimeBol: false, // 年月选择器 显示/隐藏
      currentDate: new Date(), // 当前年月
      maxDate: new Date(), // 最大月份

      month: "", // 年月接口传参
      page: 1, // 当前页码
      lastPage: 0, // 最后页码
      flogLogStatics: {}, // 收益统计
      flogLogInfo: [], // 收益明细

      headerHeight: 0, // 吸顶高度
    };
  },
  created() {
    this.getFlogLogStatics();
    this.getFlogLog();
  },
  mounted() {
    this.headerHeight = this.$refs.header.clientHeight;
  },
  methods: {
    // 获取收益统计
    getFlogLogStatics() {
      $get("/home/flogLogStatics", {
        month: formatDate(this.currentDate, "yyyy-MM"),
        action: this.columnsSel.type || null,
      }).then((res) => {
        this.flogLogStatics = res;
      });
    },

    // 获取收益明细
    getFlogLog() {
      this.month = formatDate(this.currentDate, "yyyy-MM");
      $get("/home/flogLog", {
        page: this.page,
        month: this.month,
        action: this.columnsSel.type || null,
      }).then((res) => {
		this.flogLogInfo.push(...res.data);
        this.lastPage = res.last_page;
      });
    },

    // 交易类型选择器 确认
    onConfirm(e) {
      this.columnsSel = e;
      this.showPicker = false;
      //   重置数据 start
      this.page = 1;
      this.lastPage = 0;
      this.flogLogInfo = [];
      //   重置数据 end
      this.getFlogLogStatics();
      this.getFlogLog();
    },

    // 年/月选择器 取消
    datetimeCancel() {
      this.currentDate = new Date(this.month);
      this.datetimeBol = false;
    },

    // 年/月选择器 确认
    datetimeConfirm(e) {
      this.currentDate = e;
      this.datetimeBol = false;
      //   重置数据 start
      this.page = 1;
      this.lastPage = 0;
      this.flogLogInfo = [];
      //   重置数据 end
      this.getFlogLogStatics();
      this.getFlogLog();
    },

    // 触发滚动条
    handleScroll(e) {
	  let { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
		// 滚动条触底
        if (this.lastPage > this.page) {
          this.page += 1;
          this.getFlogLog();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-profit {
  height: 100vh;
  //   padding-bottom: 15px;
  box-sizing: border-box;
  background: #f2f5fc;

  &-1 {
    padding: 20px 0;
    font-size: 16px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;

    img {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
  }

  .content {
    box-sizing: border-box;
    overflow-y: auto;
  }

  &-2,
  &-3 {
    background-color: #fff;
  }

  &-2 {
    padding: 15px;

    > div {
      line-height: 100%;
    }

    &-1 {
      display: flex;
      align-items: center;
      font-size: 16px;

      div:nth-last-of-type(1) {
        width: 7px;
        height: 7px;
        margin-left: 8px;
        transform: translateY(-3px) rotateZ(45deg);
        border-right: 2px solid #000000;
        border-bottom: 2px solid #000000;
      }
    }

    &-2 {
      margin-top: 8px;
      font-size: 13px;
      color: #808080;
      display: flex;
      justify-content: space-between;
    }
  }

  &-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 11px 17px 15px;
    margin-bottom: 1px;

    div {
      line-height: 100%;
    }

    &-1 {
      div:nth-of-type(1) {
        font-size: 16px;
      }

      div:nth-of-type(2) {
        margin-top: 8px;
        font-size: 14px;
        color: #c8c8c8;
      }
    }

    &-2 {
      font-size: 20px;
      color: #00a63a;
    }
  }

  &-3:nth-last-of-type(1) {
    margin: 0;
  }
}
</style>