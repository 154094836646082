<template>
	<div class="wallet-wrap">
		<div class="info">
			<span>可提现</span>
			<!-- <span>{{money}}</span> -->
			<span>￥{{ money }}</span>
		</div>
		<div class="operate">
			<div @click="toCashOut">提现银行卡</div>
			<div class="line"></div>
			<div class="operate-item" @click="toWithdrawParticulars">提现明细</div>
		</div>
		<div class="operate">
			<div @click="toMyCrad">我的银行卡</div>
		</div>
	</div>
</template>

<script>
import { $get } from '@/utils/request.js';
import { Cell } from 'vant';
export default {
	name: 'withdraw',
	components: {
		vanCell: Cell
	},
	data() {
		return {
			money: '0.00',
			flogLogStatics: {
				bank_num: 0,
				bank_max_num: 0
			}
		};
	},
	created() {
		this.getData();
	},
	methods: {
		// 获取收益统计
		getData() {
			$get('/home/user/withdrawal_wallet').then(data => {
				this.money = data.money;
			});

			$get('/home/flogLogStatics').then(res => {
				this.flogLogStatics = res;
			});
		},
		toCashOut() {
			$get('/home/checkWithdraw').then(res => {
				if (res.length) {
					// 去签约
					localStorage.setItem('wxLoginStatus', true);
					window.location.href = res;
				} else {
					if (this.flogLogStatics.bank_num) {
						// 有银行卡 - 去提现
						this.$router.push('/cashOut');
					} else {
						// 没有银行卡 - 去添加
						this.$router.push({ name: 'myBankCard', params: { addBtnBol: this.flogLogStatics.bank_num != this.flogLogStatics.bank_max_num } });
					}
				}
			});
		},
		toWithdrawParticulars() {
			this.$router.push({
				name: 'detailWithdrawal',
				query: {
					// item: JSON.stringify(item),
					// name: this.userStockInfo.realname
				}
			});
		},
		toMyCrad() {
			this.$router.push({ name: 'myBankCard', params: { addBtnBol: this.flogLogStatics.bank_num != this.flogLogStatics.bank_max_num } });
		}
	}
};
</script>

<style lang="scss" scoped>
.wallet-wrap {
	background: #f6f7fb;
	height: calc(100% - 16px);
	padding: 16px 12px 0;
}
.info {
	display: flex;
	justify-content: space-around;
	border-radius: 10px;
	color: #313331;
	background: white;
	line-height: 55px;
}
.info span:nth-child(1) {
	font-size: 14px;
	font-weight: bold;
}
.info span:nth-child(2) {
	font-weight: bold;
	font-size: 17px;
}
.operate {
	margin-top: 8px;
	background: white;
	color: #313331;
	border-radius: 10px;
	font-size: 12px;
	display: flex;
	justify-content: space-evenly;
	padding: 19px 0;
}
.line {
	width: 1px;
	height: 15px;
	background: #dedfe4;
}
</style>
