<template>
	<div class="withdrawParticulars-wrap" style="background: #f6f7fb;">
		<div style="width: 100%;min-height: 100vh;">
			<div class="welfare-wrap">
				<wx-open-launch-weapp
					id="launch-btn"
					username="gh_76fd9f12fd00"
					path="/pages/index/index"
					style="width: 100vw;overflow: hidden;height: 100vh;background: #f6f7fb;"
					@launch="sucFun"
					@error="errFun"
				>
					<script type="text/wxtag-template">
						<style>
							*{ margin: 0; padding: 0; } .welfare-list { padding: 10px;width:100%;} .welfare-item { width: 45%; font-size:32px  !important;height:430px;margin-bottom: 9px; box-shadow: 0 1px 1px 0 rgb(32 33 36 / 28%); border-radius: 10px; overflow: hidden; background: #fff; display: inline-block; margin: 0 auto; margin-left: 2vw; margin-top: 2vh; } .prod-image { height: 300px; width: 100%; object-fit: contain; border-radius: 10px 0 0 0; } .price-wrap { display: flex; justify-content: space-between; } .prod-info { word-break: break-all; padding: 4px 8px 8px 8px; margin-bottom: 5px; } .price-data { display: flex; padding-top: 5px; height: 20px; } .prod-desc { font-size: 32px; line-height: 32px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical; font-weight: bold; color: #303133; } .rmb { font-size: 28px; color: #ec5d29; font-weight: bold; vertical-align: middle; } .price { color: #ec5d29; font-size: 28px; font-weight: bold; vertical-align: middle; } 
						</style>

							<div class="welfare-list">
								<div class="welfare-item">
									<img class="prod-image" src="https://mall-wsdq.oss-cn-shenzhen.aliyuncs.com/attach/2022/05/e6701202205061025287521.jpg" />
									<div class="prod-info">
										<p class="prod-desc">葛圣源 三烤竹盐复合调味料260g*5袋</p>
										<p class="price-wrap"></p>
										<div class="price-data">
											<p class="rmb">￥</p>
											<p class="price">105.00</p>
										</div>
									</div>
								</div>
								<div class="welfare-item">
									<img class="prod-image" src="https://mall-wsdq.oss-cn-shenzhen.aliyuncs.com/attach/2022/05/0f0a3202205061730007730.jpg" />
									<div class="prod-info">
										<p class="prod-desc">竹之绿 竹荪100克/袋</p>
										<p class="price-wrap"></p>
										<div class="price-data">
											<p class="rmb">￥</p>
											<p class="price">49.00</p>
										</div>
									</div>
								</div>
								<div class="welfare-item">
									<img class="prod-image" src="https://mall-wsdq.oss-cn-shenzhen.aliyuncs.com/attach/2022/05/5efd4202205211438567955.jpg" />
									<div class="prod-info">
										<p class="prod-desc">竹艺轩 手工竹编花瓶</p>
										<p class="price-wrap"></p>
										<div class="price-data">
											<p class="rmb">￥</p>
											<p class="price">58.00</p>
										</div>
									</div>
								</div>
								<div class="welfare-item">
									<img class="prod-image" src="https://mall-wsdq.oss-cn-shenzhen.aliyuncs.com/attach/2022/03/ea169202203221147559816.png" />
									<div class="prod-info">
										<p class="prod-desc">添福道 SOD太真鸭 真空包装2.2kg/只</p>
										<p class="price-wrap"></p>
										<div class="price-data">
											<p class="rmb">￥</p>
											<p class="price">236.00</p>
										</div>
									</div>
								</div>
								<div class="welfare-item">
									<img class="prod-image" src="https://mall-wsdq.oss-cn-shenzhen.aliyuncs.com/attach/2022/03/9b53d20220322114054133.png" />
									<div class="prod-info">
										<p class="prod-desc">添福道 SOD礼隆鸡 真空包装1.1kg/只</p>
										<p class="price-wrap"></p>
										<div class="price-data">
											<p class="rmb">￥</p>
											<p class="price">169.00</p>
										</div>
									</div>
								</div>
							</div>
					</script>
				</wx-open-launch-weapp>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: []
		}
	},
	mounted() {},
	methods: {
		sucFun(msg) {
			console.log(msg)
		},
		errFun(msg) {
			console.log(msg)
		}
	}
}
</script>

<style>
</style>