<template>
	<van-tabbar
		v-model="active"
		class="tabBar"
		active-color="#00A63A"
		inactive-color="#434343"
		route
	>
		<van-tabbar-item
			replace
			v-for="(item, index) in list"
			:key="index"
			:to="item.to"
		>
			<template #icon="props">
				<img :src="props.active ? item.icon.active : item.icon.inactive" />
			</template>
			<span>{{ item.name }}</span>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
	import { Tabbar, TabbarItem } from "vant";
	import activeHome from "@/assets/tabBar/active/home.png";
	import inactiveHome from "@/assets/tabBar/inactive/home.png";
	import activeShop from "@/assets/tabBar/active/shop.png";
	import inactiveShop from "@/assets/tabBar/inactive/shop.png";
	import activeTeam from "@/assets/tabBar/active/team.png";
	import inactiveTeam from "@/assets/tabBar/inactive/team.png";
	import activeMy from "@/assets/tabBar/active/my.png";
	import inactiveMy from "@/assets/tabBar/inactive/my.png";
	export default {
		name: "TabBar",
		components: {
			vanTabbar: Tabbar,
			vanTabbarItem: TabbarItem,
		},
		data() {
			return {
				active: 0,
				list: [
					{
						name: "首页",
						to: { name: "home" },
						icon: {
							active: activeHome,
							inactive: inactiveHome,
						},
					},
          {
            name: "兑换",
						to: { name: "conversion" },
						icon: {
							active: activeHome,
							inactive: inactiveHome,
						}
          },
					// {
					// 	name: "购机",
					// 	to: { name: "shop" },
					// 	icon: {
					// 		active: activeShop,
					// 		inactive: inactiveShop,
					// 	},
					// },
					// {
					// 	name: "无塑家园",
					// 	to: { name: "team" },
					// 	icon: {
					// 		active: activeTeam,
					// 		inactive: inactiveTeam,
					// 	},
					// },
					{
						name: "我的",
						to: { name: "my" },
						icon: {
							active: activeMy,
							inactive: inactiveMy,
						},
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.tabBar {
		height: 55px;
		box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
	}
</style>