<template>
  <div class="login">
    <!-- 验证码登录 start -->
    <template>
      <div class="main codeLogin">
        <div class="list-inp" v-if="params.share_code" style="display: grid;">
          <label>邀请码</label>
          <input
            disabled
            v-model="params.share_code"
          />
        </div>
        <div class="list-inp">
          <input
            type="tel"
            placeholder="请输入手机号"
            maxlength="11"
            v-model="params.account"
          />
        </div>
        <div class="list-inp code-list-inp">
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="params.code"
          />
          <div class="codeBtn">
            <span
              class="repeat"
              v-if="fetchCodeSend"
            >{{ countDown }}s后重新获取</span>
            <span
              v-else
              @click="fetchCode"
            >获取验证码</span>
          </div>
        </div>

        <div
          class="submitBtn"
          @click="submit"
        >
          <button class="btn">登 录</button>
        </div>
        <!-- <p
          class="wxloginBtn"
          @click="handleWxLogin"
        >微信用户快捷登录</p> -->
      </div>
    </template>
    <!-- 验证码登录 end -->
  </div>
</template>

<script>
import { $get, $post } from "@/utils/request";
import { mpLogin, $wxLogin } from "@/utils/wxUtils";

const params = {
  account: "", // 手机号码
  code: "", // 手机验证码
  share_code: null
};

export default {
  data() {
    return {
      params: JSON.parse(JSON.stringify(params)),

      countDown: 60, // 验证码倒计时
      fetchCodeSend: false, // 是否发送验证码

      rememberPsdBol: false, // 是否记住密码

      isClick: true // 是否可点击
      // share_code: null
    };
  },
  mounted() {
    console.log(localStorage.getItem("share_code"))
    this.params.share_code = localStorage.getItem("share_code");
  },
  methods: {
    // 获取手机验证码
    fetchCode() {
      let { account } = this.params;
      if (!account) {
        this.$toast("请输入手机号");
      } else if (!/^1\d{10}$/.test(account)) {
        this.$toast("请输入正确的手机号");
      } else {
        this.register();
      }
    },

    // 发送验证码
    register() {
      let { account } = this.params;
      if (this.isClick) {
        this.isClick = false;
        // 发送验证码
        $post("/home/sendSms", { mobile: account })
          .then(res => {
            this.fetchCodeSend = true;
            let settime = setInterval(() => {
              this.countDown--;
              if (this.countDown === 0) {
                clearInterval(settime);
                this.fetchCodeSend = false;
                this.countDown = 60;
              }
            }, 1000);
            this.isClick = true;
            this.$toast("验证码已发送成功");
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    },

    // 登录
    submit() {
      let { account, code } = this.params;
      if (!account) {
        this.$toast("请输入手机号");
      } else if (!/^1\d{10}$/.test(account)) {
        this.$toast("请输入正确的手机号");
      } else if (!code) {
        this.$toast("请输入验证码");
      } else {
        if (this.isClick) {
          this.isClick = false;
          $post("/login/users", {
            username: account,
            sms_code: code,
            parent: localStorage.getItem("share_code") || null
          })
            .then(res => {
              let { token_type, access_token } = res;
              this.isClick = true;
              localStorage.setItem("token", token_type + " " + access_token);
              this.$router.push("/");
              localStorage.removeItem("share_code");
            })
            .catch(err => {
              this.isClick = true;
            });
        }
      }
    },

    // 微信快捷登录
    handleWxLogin() {
      mpLogin()
        .then(res => {
          localStorage.setItem(
            "token",
            res.token_type + " " + res.access_token
          );
          this.$router.push("/");
        })
        .catch(err => {
          localStorage.setItem("mpLoginStatus", true);
        });
    }
  }
};
</script>

<style scoped>
input,
button {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.login {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  color: #272f37;
  font-size: 16px;
  font-weight: 300;
}
.main {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 30px;
}

/* 密码登录 start */
.psdLogin .list-inp {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.psdLogin .list-inp.phone {
  margin-bottom: 20px;
}
.psdLogin .list-inp img {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.psdLogin .list-inp input {
  width: 264px;
  height: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 16px;
  font-weight: 400;
}
.psdLogin .list-inp input::placeholder {
  color: #999999;
  font-weight: 300;
}

.psdLogin .rememberPsd {
  margin: 10px 0 84px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.psdLogin .rememberPsd img {
  width: 19px;
  margin-right: 4px;
}
/* 密码登录 end */

/* 验证码登录 start */
.codeLogin {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("../../assets/login/codeLogin_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.codeLogin .list-inp {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}
.codeLogin .code-list-inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 142px;
}
.codeLogin .list-inp input {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #bcc5d3;
}
.codeLogin .code-list-inp input {
  width: 182px;
}
.codeLogin .codeBtn {
  width: 103px;
  height: 38px;
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #04aa7c;
  color: #fff;
  font-weight: 400;
  text-align: center;
}
/* 验证码登录 end */

.submitBtn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  background-color: #04aa7c;
  border-radius: 100px;
  margin-bottom: 20px;
  text-align: center;
}
.submitBtn button {
  color: #fff;
  font-size: 20px;
}

.wxloginBtn {
  color: #6b8eac;
  text-align: center;
  font-weight: bold;
}
</style>
