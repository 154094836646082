<template>
  <div class="wallet-wrap">
    <div class="info">
      <span>奖金池</span>
      <span>￥{{prize_pool}}</span>
      <!-- <span>￥0.00</span> -->
    </div>
    <div class="operate">
      <div class="operate-item" @click="toParticulars">
        <img src="../../../assets/common/detail.png" />
        <span>奖金明细</span>
      </div>
      <div class="line"></div>
      <div class="operate-item">
        <img src="../../../assets/common/ranking.png" />
        <span>我的排名：{{ranking}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { $get } from "@/utils/request.js"
	import { Cell } from "vant"
	export default {
		name: "wallet",
		components: {
			vanCell: Cell,
		},
		data() {
			return {
				prize_pool: '0.00',
        ranking: null
			}
		},
		created() {
			this.getData()
		},
		methods: {
			// 获取收益统计
			getData() {
				$get('/home/user/prize_pool').then(data => {
					this.prize_pool = data.prize_pool
          this.ranking = data.ranking
				})
			},
      toParticulars () {
        this.$router.push({ name: 'prizeDetail' })
      }
		}
	}
</script>

<style lang="scss" scoped>
	.wallet-wrap {
    background: #F6F7FB;
    height: calc(100% - 16px);
    padding: 16px 12px 0;
	}
  .info {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    color: #313331;
    background: white;
    line-height: 55px;
  }
  .info span:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
  }
  .info span:nth-child(2) {
    font-weight: bold;
    font-size: 17px;
  }
  .operate {
    margin-top: 8px;
    background: white;
    color: #313331;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 24px 0;
  }
  .line {
    width: 1px;
    height: 30px;
    background: #DEDFE4;
    margin-top: 7px;
  }
  .operate-item {
    display: grid;
    text-align: center;
    height: 44px;
  }
  .operate-item img {
    width: 28px;
    height: 28px;
    margin: auto;
  }
</style>