<template>
	<div class="detail-wrap">
    <div class="detail-list">
      <div class="title">
        <span>时间</span>
        <span>订单号</span>
        <span>金额</span>
      </div>
      <div class="content" v-for="(item, index) in list">
        <span>{{item.created_date}}</span>
        <span>{{item.order_sn}}</span>
        <span>￥{{item.balance}}</span>
      </div>

      <wd-infinite-load ref="loadmore" class="infinite" @loadmore="loadmore" :loading="loading"></wd-infinite-load>
    </div>
  </div>
</template>

<script>
  import { $get } from "@/utils/request.js"
  import Vue from 'vue'
  import { InfiniteLoad } from 'wot-design'
  Vue.use(InfiniteLoad)
	export default {
		data() {
			return {
        list: [],
        page: 1,
        limit: 20,
        loading: false,
        totalPage: 1,
        loadEnd: false
			}
		},
		created() {
      this.list = []
		},
    methods: {
      loadmore () {
        if (this.loadEnd) return
        this.loading = true
        $get("/home/wallet/index", { account: "cpq", page: this.page, limit: this.limit }).then((res) => {
          this.totalPage = res.last_page
          this.list = this.list.concat(res.data)
          if (this.totalPage === this.page) {
            this.loadEnd = true
            this.$refs.loadmore.loadEnd()
          } else {
            this.page ++
          }
          this.loading = false
        }).catch(err => {
          this.$refs.loadmore.loadEnd()
        })
      }
    }
	}
</script>

<style lang="scss" scoped>
	.detail-wrap {
    background: #F6F7FB;
    height: calc(100% - 16px);
    padding-top: 16px;
	}
  .detail-list {
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0 12px;
    padding: 22px 15px;
  }
  .title {
    color: #A7A8AE;
    font-size: 15px;
    display: flex;
    margin-bottom: 8px;
  }
  .title span:nth-child(1) {
    flex: 2;
  }
  .title span:nth-child(2) {
    flex: 4;
  }
  .title span:nth-child(3) {
    flex: 1;
  }
  .content {
    font-size: 13px;
    color: #313331;
    padding-bottom: 8px;
    padding-top: 8px;
    /* padding-top: 100px; */
    display: flex;
    border-bottom: solid 1px #E9E9EC;
  }
  .content span:nth-child(1) {
    flex: 2;
  }
  .content span:nth-child(2) {
    flex: 4;
  }
  .content span:nth-child(3) {
    flex: 1;
  }
  .infinite {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }
</style>