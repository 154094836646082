import 'amfe-flexible'
import Vue from 'vue'
import vueEsign from 'vue-esign'
import App from './App.vue'
import router from './router'
import store from './store'
import VConsole from 'vconsole'
// let vConsole = new VConsole() // eslint-disable-line
const echarts = require('echarts')
Vue.prototype.$echarts = echarts
import { Toast } from 'vant'
// import {z-paging} from "z-paging"
Vue.use(Toast)
Vue.prototype.$toast = Toast
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(vueEsign)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
