<template>
  <router-view />
</template>

<script>
import { $wxLogin, fetchWXSignature, mpLogin } from "@/utils/wxUtils";
import { $get, $post } from "@/utils/request";
import { mapMutations } from "vuex";
import { getCookie, delCookie } from "@/utils/util.js";
export default {
  created() {
    // 通过分享链接进入
    if (this.$route.query.share_code) {
      localStorage.setItem("share_code", this.$route.query.share_code);
    }

    if (localStorage.getItem("wxLoginStatus")) {
      // wxLoginStatus 标识-不跳转微信授权
      if (localStorage.getItem("mpLoginStatus")) {
        // 一键微信登录-回来
        localStorage.removeItem("mpLoginStatus");
        mpLogin().then((res) => {
          localStorage.setItem(
            "token",
            res.token_type + " " + res.access_token
          );
          this.$router.push("/");
        }).catch(err => {
          console.log(err.msg)
        });
        return
      }
      // 微信授权-回来
      localStorage.removeItem("wxLoginStatus");
      fetchWXSignature(); // 获取微信签名

      if (getCookie("company_audit")) {
        // 企业认证完成标识 - 去填写企业信息
        delCookie("company_audit");
        this.$router.push({ name: "authenticationEnterprise" });
      } else if (getCookie("front_path") == "order_list") {
        // 签署成功 跳转订单列表
        delCookie("front_path");
        this.$router.push({ name: "myOrder", params: { page: "all" } });
      } else {
        this.getInfo();
      }
    } else {
      // 去微信授权
      localStorage.setItem("wxLoginStatus", true);
      $wxLogin();
    }
  },

  methods: {
    ...mapMutations(["setBasicInfo"]),

    // 全局配置接口
    getInfo() {
      if (!localStorage.getItem("basicInfo")) {
        $get("/home/setting/basic").then((res) => {
          localStorage.setItem("basicInfo", JSON.stringify(res));
        });
      }

      this.setBasicInfo(JSON.parse(localStorage.getItem("basicInfo")));
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.van-form .van-cell {
  padding: 16px;

  > div {
    line-height: 18px;
    font-size: 16px;
  }

  .van-cell__title {
    color: #323233;
  }
}

.van-form .red input::placeholder {
  color: #33b761;
}

.title8 .van-cell__title {
  width: 8em;
}

.title10 .van-cell__title {
  width: 10em;
}

.enterprise,
.join-team,
.binding-card {
  .van-form .van-cell {
    padding-left: 24px;
  }

  .van-form .join-team-1 .van-cell {
    padding-left: 16px;
  }

  .van-cell--required::before {
    left: 15px;
  }

  .van-form .textarea,
  .van-form .uploader {
    padding-left: 16px;
    display: block;

    .van-cell__title {
      margin-left: 7px;
      width: 95%;
    }
  }

  .van-form .textarea {
    .van-cell__value {
      box-sizing: border-box;
      padding: 8px 10px;
      margin-top: 10px;
      width: 344px;
      background: #f2f6fc;
      border-radius: 2px;
    }

    .van-field__control,
    .van-field__body {
      height: 135px;
    }
  }

  .van-form {
    .uploader {
      .van-cell__value {
        margin-top: 10px;

        .van-uploader__wrapper {
          width: 342px;
          height: 170px;
        }

        .van-uploader__preview {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;

          .van-image {
            width: 100%;
            height: 100%;
          }

          .van-uploader__file {
            width: 100%;
            height: 100%;

            i {
              font-size: 46px;
            }
          }
        }

        .van-field__control {
          flex-direction: column;
        }
      }

      .van-cell__title {
        width: 100%;
      }
    }

    .uploader2 {
      .van-cell__value {
        .van-uploader__wrapper {
          height: 200px;
        }
      }
    }
  }
}

.my-store {
  .van-tabs--line .van-tabs__wrap {
    height: 40px;
  }

  .van-tab__text--ellipsis {
    font-size: 14px;
  }

  .van-tabs__line {
    background: #00a63a;
  }
}

.van-collapse {
  .van-cell {
    padding: 12px;

    .van-cell__title {
      display: flex;
      align-items: center;
      line-height: 100%;
    }
  }

  .van-collapse-item__content {
    padding: 0;
  }
}

.myOrder,
.paving {
  .van-tabs--line .van-tabs__wrap {
    height: 40px;
  }
}

.van-form {
  .order-confirm-2 {
    .van-cell__title {
      font-size: 16px;
      color: #00a63a;
    }
  }

  .van-stepper__plus,
  .van-stepper__plus:active {
    background: #00a63a;
    color: #ffffff;
  }

  .van-stepper__minus,
  .van-stepper__minus:active {
    background-color: #f2f3f5;
  }

  .order-confirm-3 {
    .van-field__control::placeholder {
      color: #00a63a;
    }
  }
}

.order-confirm {
  .title10 {
    .van-field__label {
      display: flex;
      align-items: center;
    }
  }
}
</style>
