<template>
	<div>
		<div class="chat-box">
			<img class="bg" src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220427_140405_6268dcd58728e.png"></img>
			<div class="info">
				<div style="padding-left: 44px;padding-right: 39px;">
					<span class="name">客服小妍</span>
					<span class="phone">热线：400-966-8819</span>
					<span class="scan">扫码添加客服微信</span>
				</div>
				<img class="qrcode"
					src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220426_175828_6267c244d9c5d.jpg"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		previewImage(e) {
			var url = e.target.dataset.src;
			wx.previewImage({
				current: url,
				urls: [url],
				success: res => {
					console.log(res)
				}
			})
		}
	}
}
</script>
<style scoped>
  .chat-box {
    padding: 0 16px;
    padding-top: 16px;
  }
  .bg {
    width: 100%;
    /* height: ; */
  }
  .info {
		display: flex;
		padding-top: 24px;
	}
  .name {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
  .phone {
    font-size: 14px;
    font-weight: 500;
    color: #4b4b4b;
    line-height: 22px;
    padding-top: 23px;
    display: block;
  }
  .scan {
    font-size: 12px;
    font-weight: 400;
    color: #4b4b4b;
    line-height: 20px;
    display: block;
  }
  .qc {
    width: 190px;
    padding-left: 100px;
  }
  .qrcode {
    width: 94px;
  }
</style>
