<template>
  <div class="wallet-wrap">
    <div class="info">
      <span>我的团队</span>
      <span>{{team_num}}</span>
    </div>
    <div class="operate">
      <div class="operate-item">
        <img src="../../../../assets/common/ranking.png" />
        <span>一级人数：{{team_one_num}}</span>
      </div>
      <div class="line"></div>
      <div class="operate-item">
        <img src="../../../../assets/common/ranking.png" />
        <span>二级人数：{{team_two_num}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { $get } from "@/utils/request.js"
	import { Cell } from "vant"
	export default {
		name: "wallet",
		components: {
			vanCell: Cell,
		},
		data() {
			return {
				team_num: 0,
        team_one_num: 0,
        team_two_num: 0
			}
		},
		created() {
			this.getData()
		},
		methods: {
			// 获取收益统计
			getData() {
				$get('/home/user/team').then(data => {
					this.team_num = data.team_num
          this.team_one_num = data.team_one_num
          this.team_two_num = data.team_two_num
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wallet-wrap {
    background: #F6F7FB;
    height: calc(100% - 16px);
    padding: 16px 12px 0;
	}
  .info {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    color: #313331;
    background: white;
    line-height: 55px;
  }
  .info span:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
  }
  .info span:nth-child(2) {
    font-weight: bold;
    font-size: 17px;
  }
  .operate {
    margin-top: 8px;
    background: white;
    color: #313331;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 24px 0;
  }
  .line {
    width: 1px;
    height: 30px;
    background: #DEDFE4;
    margin-top: 7px;
  }
  .operate-item {
    display: grid;
    text-align: center;
    height: 44px;
  }
  .operate-item img {
    width: 28px;
    height: 28px;
    margin: auto;
  }
</style>