<template>
  <div class="application-card">
    <van-cell
      class="application-card-1"
      :border="false"
      title="功能应用"
      :clickable="false"
    />
    <div class="application-card-2">
      <!-- <router-link
				v-for="(item, index) in info"
				:key="index"
				:to="item.to ? item.to : ''"
				custom
				v-slot="{ navigate }"
			>
			<IconBlock @click.native="navigate"
					:info="item"
					color="#333333"
					width="1.55rem"
				/>
			</router-link> -->
      <IconBlock
        v-for="(item, index) in info"
        :key="index"
        v-slot="{ toLink }"
        @click.native="toLink(item.to, index)"
        :info="item"
        color="#333333"
        width="1.55rem"
      />
    </div>
  </div>
</template>

<script>
import { Cell } from "vant";
import { mapState } from "vuex";
import IconBlock from "@/components/IconBlock.vue";
export default {
  components: {
    vanCell: Cell,
    IconBlock,
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      info: [
        {
          url: require("@/assets/my/wallet.png"),
          content: "我的钱包",
          to: { name: "wallet" },
        },
        {
          url: require("@/assets/my/poster.png"),
          content: "分享海报",
          to: { name: "shareAlready" || "shareNot" },
        },
        {
          url: require("@/assets/my/contract.png"),
          content: "我的合同",
          to: { name: "contract" },
        },
        {
          url: require("@/assets/my/service.png"),
          content: "联系客服",
          to: { name: "serviceStaff" },
        },
        {
          url: require("@/assets/my/dividend.png"),
          content: "分红券",
          to: { name: "dividend" },
        },
        {
          url: require("@/assets/my/product.png"),
          content: "产品券",
          to: { name: "product" },
        },
        {
          url: require("@/assets/my/team.png"),
          content: "我的团队",
          to: { name: "myTeam" },
        },
        {
          url: require("@/assets/my/performance.png"),
          content: "我的业绩",
          to: { name: "performance" },
        },
        {
          url: require("@/assets/my/signOut.png"),
          content: "退出登录",
        },
        {
          url: '',
          content: "",
        },
      ],
    };
  },
  methods: {
    toLink(item, index) {
      if (item) {
        console.log(item)
        this.$router.push(item);
      } else {
        // if (index == 3 || index == 4 || index == 8) {
        //   // 联系客服 / 问题反馈
        //   let { customer_service } = this.userInfo;
        //   if (customer_service) {
        //     window.location.href = "tel://" + customer_service;
        //   } else {
        //     this.$toast("该功能暂未开放");
        //   }
        // } else
        if (index == 3) {
          // 联系客服 / 问题反馈
          this.$toast("400-966-8819");
        } else if (index == 8) {
          // 退出登录
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.application-card {
  width: 345px;
  height: 200px;
  background: #ffffff;
  border-radius: 12px;

  &-1 {
    padding: 11px 0;
    font-size: 16px;
    border-radius: 12px;

    .van-cell__title span {
      margin-left: 10px;
      color: #333333;
      font-weight: bold;
    }
  }

  &-2 {
    margin-top: 8px;
    padding: 0 13px;
    height: 121px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;

    .badge {
      margin-right: 5%;
    }

    .badge:nth-of-type(4n) {
      margin: 0;
    }
  }
}
</style>