import { $wxLogin } from "@/utils/wxUtils";
import CONFIG from '@/config/';
import router from '@/router';
import axios from 'axios';
import Vue from 'vue';
const API_HOST = CONFIG.apiHost

// 获取cookie键值
export const getCookie = name => {
  var strcookie = document.cookie; //获取cookie字符串
  var arrcookie = strcookie.split("; "); //分割
  //遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split("=");
    if (arr[0] == name) {
      return arr[1];
    }
  }
  return "";
}

// 取消请求
const CancelToken = axios.CancelToken

class HttpRequest {
  constructor(opts) {
    this.defaults = {
      baseURL: '',
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    this.defaults = {
      ...this.defaults,
      ...opts
    }
  }

  interceptors(install) {
    install.interceptors.request.use(config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }

      const cookie = getCookie('laravel_session');
      if (cookie) {
        config.headers.cookie = `laravel_session=${cookie}`;
      }

      // 得到参数中的requestName 字段， 用于判断下次请求
      // get / post 取值不同
      const requestName = config.method.toLocaleUpperCase() === 'POST' ? config.data.requestName : config.params.requestName
      // 判断 requestName 存在取消上次请求
      if (requestName) {
        if (config[requestName] && config[requestName].cancel) {
          config[requestName].cancel()
        }
        config.cancelToken = new CancelToken(c => {
          config[requestName] = {}
          config[requestName].cancel = c
        })
      }

      return config
    }, err => {
      return Promise.reject(err)
    });

    install.interceptors.response.use(response => {
      const {
        status,
        data: res,
        msg
      } = response;
      let {
        data
      } = res;
      if (typeof res === 'string') {
        data = JSON.parse(res);
      } else if (!res.status && res.code != 402) {
        Vue.prototype.$toast(res.msg)
        return Promise.reject(res)
      }

      return data
    }, err => {
      let status = JSON.parse(JSON.stringify(err)).status
      if (status == 401) {
        if (err.response.data.message == "Unauthorizedwechat.") {
          // 微信授权
          localStorage.setItem("wxLoginStatus", true);
          $wxLogin();
        }else if(err.response.data.message == "Unauthenticated.") {
          // 跳转登录页 并 重新授权
          localStorage.removeItem('token');
          router.push('/login');
          // 微信授权
          localStorage.setItem("wxLoginStatus", true);
          $wxLogin();
        }
      } else if (status == 422) {
        let tips = [];
        for (const key in err.response.data.errors) {
          if (Object.hasOwnProperty.call(err.response.data.errors, key)) {
            console.log(err.response.data.errors[key])
              tips.push(err.response.data.errors[key])
              const element = err.response.data.errors[key];
          }
      }
        console.log(tips)
        Vue.prototype.$toast(tips[0][0])
      } else if (status == 500) {
        Vue.prototype.$toast('网络异常')
      }
      return Promise.reject(err)
    });
  }

  request(options) {
    options = {
      ...this.defaults,
      ...options
    }
    const instance = axios.create(options);
    this.interceptors(instance);
    return instance
  }
}
console.log('-----环境-----', process.env.NODE_ENV, API_HOST)
const http = new HttpRequest({
  // baseURL: process.env.NODE_ENV === 'production' ? API_HOST : '',
  baseURL: process.env.NODE_ENV === 'production' ? API_HOST : API_HOST,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
}).request();

// 将axios 的 post 方法绑定在vue实例上的$post
const $post = (url, data = {}) => {
  return http.post(url, data)
}

// 将axios 的 get 方法绑定在vue实例上的$get
const $get = (url, params = {}) => {
  return http.get(url, {
    params
  })
}

export {
  $post,
  $get
};