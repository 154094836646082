// 日期时间转换
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
};

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
};


// 判断是否为大屏手机
export const isBigScreen = () => {
  return screen.height >= 812
}

// 获取cookie键值
export const getCookie = name => {
  var strcookie = document.cookie; //获取cookie字符串
  var arrcookie = strcookie.split("; "); //分割
  //遍历匹配
  for (var i = 0; i < arrcookie.length; i++) {
    var arr = arrcookie[i].split("=");
    if (arr[0] == name) {
      return arr[1];
    }
  }
  return "";
}


// 删除cookie值
export const delCookie = name => {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}


//除法
// export const accDiv = (arg1,arg2) => {
//   var t1=0,t2=0,r1,r2; 
//   try{t1=arg1.toString().split(".")[1].length}catch(e){} 
//   try{t2=arg2.toString().split(".")[1].length}catch(e){} 
//   with(Math){ 
//      r1=Number(arg1.toString().replace(".","")) 
//      r2=Number(arg2.toString().replace(".","")) 
//       return accMul((r1/r2),pow(10,t2-t1)); 
//   } 
// } 
//乘法 
export const accMul = (arg1,arg2) => { 
  var m=0,s1=arg1.toString(),s2=arg2.toString(); 
  try{m+=s1.split(".")[1].length}catch(e){} 
  try{m+=s2.split(".")[1].length}catch(e){} 
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m) 
} 
//加法 
export const accAdd = (arg1,arg2) => { 
  var r1,r2,m; 
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
  m=Math.pow(10,Math.max(r1,r2)) 
  return (arg1*m+arg2*m)/m 
} 
//减法 
export const Subtr = (arg1,arg2) => { 
  var r1,r2,m,n; 
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
  m=Math.pow(10,Math.max(r1,r2)); 
  n=(r1>=r2)?r1:r2; 
  return ((arg1*m-arg2*m)/m).toFixed(n); 
}