<template>
	<div class="detail-wrap">
    <div class="select" @click="showAction = true">
      <span>{{trade_type == 'gdsy' ? '固定补贴' : trade_type == 'tc' ? '提成' : '资产类型'}}</span>
      <img src="../../../assets/wallet/arrows.png" />
    </div>
    <div class="detail-list">
      <div class="title">
        <span>时间</span>
        <span>类型</span>
        <span>金额</span>
      </div>
      <!-- <div class="content" v-for="index in 5">
        <span>2022.4.18</span>
        <span>固定补贴</span>
        <span>20.55</span>
      </div> -->

      <div class="content" v-for="(item, index) in list">
        <span>{{item.created_date}}</span>
        <span>{{item.order_sn}}</span>
        <span>1</span>
      </div>
      <wd-infinite-load v-show="list.length > limit" ref="loadmore" class="infinite" @loadmore="loadmore" :loading="loading"></wd-infinite-load>
    </div>
    
    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />
  </div>
</template>

<script>
  import { ActionSheet } from 'vant';
  import { $get } from "@/utils/request.js"
  import Vue from 'vue'
  import { InfiniteLoad } from 'wot-design'
  Vue.use(InfiniteLoad)
	export default {
    components: {
      vanActionSheet: ActionSheet
    },
		data() {
			return {
        showAction: false,
        actions: [
          { name: '固定补贴', value: 'gdsy' },
          { name: '提成', value: 'tc' }
        ],
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        totalPage: 1,
        trade_type: '',
        loadEnd: false
			}
		},
		created() {
		},
    methods: {
      loadmore () {
        if (this.loadEnd) return
        console.log("请求了")
        this.loading = true
        $get("/home/wallet/index", { account: "money", page: this.page, limit: this.limit, trade_type: this.trade_type }).then((res) => {
          this.totalPage = res.last_page
          this.list = this.list.concat(res.data)
          if (this.totalPage === this.page) {
            this.loadEnd = true
            this.$refs.loadmore.loadEnd()
          } else {
            this.page ++
          }
          this.loading = false
        }).catch(err => {
          this.$refs.loadmore.loadEnd()
        })
      },
      onclick () {
        console.log("1")
        this.showAction = true
      },
      onSelect (item) {
        console.log("进来罗")
        this.loading = false
        this.trade_type = item.value
        this.page = 1
        this.list = []
        this.loadEnd = false
        this.totalPage =  1
        this.loadmore()
        // this.$refs.loadmore.reset()
      }
    }
	}
</script>

<style lang="scss" scoped>
	.detail-wrap {
    background: #F6F7FB;
    height: calc(100% - 10px);
    padding-top: 10px;
	}
  .select {
    color: #A7A8AE;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
  }
  .select span {
    vertical-align: middle;
    margin-right: 6px;
  }
  .select img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  .detail-list {
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0 12px;
    padding: 22px 15px;
  }
  .title {
    color: #A7A8AE;
    font-size: 15px;
    display: flex;
    margin-bottom: 8px;
  }
  .title span:nth-child(1) {
    flex: 2;
  }
  .title span:nth-child(2) {
    flex: 4;
  }
  .title span:nth-child(3) {
    flex: 1;
  }
  .content {
    font-size: 13px;
    color: #313331;
    /* margin: 8px 0; */
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    border-bottom: solid 1px #E9E9EC;
  }
  .content span:nth-child(1) {
    flex: 2;
  }
  .content span:nth-child(2) {
    flex: 4;
  }
  .content span:nth-child(3) {
    flex: 1;
  }
  .infinite {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }
</style>