<template>
	<div class="ranking-card">
		<div class="ranking-card-1">精英排行榜</div>

		<div
			v-for="item in info"
			:key="item.id"
			:class="`ranking-card-block ranking-card-block-${item.id}`"
			:style="{
				'background-image': `url(${require(`@/assets/team/ranking${item.id}_1.png`)})`,
			}"
		>
			<img :src="item.avatar" />
			<div
				class="content"
				:style="{
					'background-image': `url(${require(`@/assets/team/ranking${item.id}_2.png`)})`,
				}"
			>
				第 {{ item.id }} 名
			</div>
		</div>

		<router-link class="ranking-card-2" :to="{ name: 'eliteRanking' }"
			>查看更多详情 >></router-link
		>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Array,
				default: () => [],
			},
		},
	};
</script>

<style lang="scss" scoped>
	.ranking-card {
		width: 345px;
		height: 175px;
		background: linear-gradient(219deg, #17cb56 0%, #00a63a 100%);
		border-radius: 12px;
		position: relative;

		> div,
		img {
			position: absolute;
		}

		img {
			border-radius: 50%;
		}

		&-1 {
			width: 127px;
			height: 34px;
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 68, 24, 0.3);
			border-radius: 100px;

			font-size: 16px;
			line-height: 34px;
			color: #000000;
			text-align: center;

			margin: auto;
			top: -17px;
			left: 0;
			right: 0;
		}

		&-2 {
			position: absolute;
			font-size: 13px;
			color: #ffffff;
			line-height: 100%;
			bottom: 14px;
			left: 50%;
			transform: translateX(-50%);
		}

		&-block {
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}

		&-block-2,
		&-block-3 {
			width: 88px;
			height: 68.61px;
			top: 59px;

			img {
				width: 54px;
				height: 54px;
				top: 12px;
				left: 15px;
			}

			.content {
				position: absolute;
				width: 64px;
				height: 22px;
				margin: auto;
				left: 0;
				right: 0;
				bottom: -6px;
				background-size: 100% 100%;

				font-size: 12px;
				color: #ffffff;
				text-align: center;
				line-height: 160%;
			}
		}

		&-block-2 {
			left: 9px;
		}

		&-block-1 {
			width: 111.63px;
			height: 88.41px;
			top: 27px;
			margin: auto;
			left: 0;
			right: 0;

			img {
				width: 70px;
				height: 70px;
				top: 16px;
				left: 20px;
			}

			.content {
				position: absolute;
				width: 76px;
				height: 26px;
				margin: auto;
				left: 0;
				right: 0;
				bottom: -9px;
				background-size: 100% 100%;

				font-size: 14px;
				color: #ffffff;
				text-align: center;
				line-height: 160%;
			}
		}

		&-block-3 {
			right: 9px;

			img {
				left: 16px;
			}
		}
	}
</style>