<template>
  <div class="order-confirm">
    <div class="order-confirm-title">商品信息</div>
    <div class="order-confirm-1">
      <img
        class="order-confirm-1-1"
        :src="info.product && info.product.main_img"
      />
      <div class="order-confirm-1-2">
        <div class="title">{{ info.product && info.product.name }}</div>
        <div class="content">{{ info.product && info.product.remark }}</div>
      </div>
    </div>

    <van-form @submit="onSubmit">
      <van-field
        class="order-confirm-2"
        center
        readonly
        name="count"
        :label="`￥${info.product && info.product.price}`"
      >
        <template #right-icon>
          <van-stepper v-model="count" />
        </template>
      </van-field>

      <div class="order-confirm-title">购买信息</div>

      <!-- <van-field
        class="order-confirm-3"
        :readonly="!(info.user && info.user.change_area)"
        name="area_name"
        :value="area_name || '请选择加盟商区域'"
        label="加盟商区域"
        input-align="right"
        error-message-align="right"
        right-icon="arrow"
        @click="handleArea"
      /> -->

      <van-field
        class="order-confirm-3"
        readonly
        name="realname"
        :value="realname"
        label="推荐人"
        input-align="right"
        error-message-align="right"
        @click="handleReal"
      />

      <div class="order-confirm-5">
        <van-radio-group v-model="pay_type">
          <van-field
            :clickable="false"
            @click="pay_type = '0'"
            center
            readonly
            class="title10"
          >
            <template #label>
              <img :src="require('@/assets/other/wechat.png')" />
              <span>微信支付</span>
            </template>
            <template #right-icon>
              <van-radio
                name="0"
                checked-color="#33b761"
              />
            </template>
          </van-field>

          <van-field
            :clickable="false"
            @click="pay_type = '3'"
            center
            readonly
            class="title10"
          >
            <template #label>
              <img :src="require('@/assets/other/wallet.png')" />
              <span>线下支付并签约</span>
            </template>
            <template #right-icon>
              <van-radio
                name="3"
                checked-color="#33b761"
              />
            </template>
          </van-field>
        </van-radio-group>
      </div>

      <div class="order-confirm-6">
        <div class="order-confirm-6-1">
          合计:
          <span>￥{{ info.product ? info.product.price * count : 0 }}.00</span>
          <!-- <span>{{ info.product.price}}</span> -->
        </div>
        <van-button
          class="order-confirm-6-2"
          round
          block
          type="info"
          native-type="submit"
        >提交订单</van-button>
      </div>
    </van-form>

    <!-- 选择省市区 -->
    <van-popup
      position="bottom"
      v-model="areaDialogBol"
    >
      <van-area
        title="选择加盟商区域"
        :area-list="areaList"
        @cancel="areaDialogBol = false"
        @confirm="submitArea"
      />
    </van-popup>

    <!-- 推荐人修改弹框 -->
    <van-popup
      class="realDialogBox"
      v-model="realDialogBol"
    >
      <div class="realDialog">
        <input
          v-model="newRealname"
          placeholder="请输入推荐人电话"
        />
        <button @click="submitReal">提交</button>
      </div>
    </van-popup>

    <!-- 上传凭证 -->
    <div
      class="uploadBox"
      v-if="pay_type == 3"
    >
      <UpLoad @finish="uploadFinish">
        <img
          class="pay_voucher"
          :src="pay_voucher.complete_url"
          v-if="pay_voucher"
        />
      </UpLoad>

      <p v-if="!pay_voucher">拍照上传支付凭证和身份证</p>
    </div>
  </div>
</template>

<script>
import {
  Stepper,
  Form,
  Field,
  Popup,
  Picker,
  Button,
  RadioGroup,
  Radio,
  Area
} from "vant";
import { $get, $post } from "@/utils/request.js";
import UpLoad from "@/components/UpLoad.vue";
import { areaList } from "@vant/area-data";
import { $wxPay } from "@/utils/wxUtils.js";
export default {
  name: "orderConfirm",
  components: {
    vanStepper: Stepper,
    vanForm: Form,
    vanField: Field,
    vanButton: Button,
    vanPopup: Popup,
    vanPicker: Picker,
    vanRadio: Radio,
    vanRadioGroup: RadioGroup,
    vanArea: Area,
    UpLoad
  },
  data() {
    return {
      areaList, // 省市区数据

      info: {},
      count: 1.00, // 选择商品数量
      area_name: "", // 加盟商区域
      newArea_name: "", // 修改推荐人后返回的加盟商区域
      realname: "", // 推荐人
      newRealname: "", // 弹框修改推荐人
      pay_type: "3", // 0-微信支付，3-线下支付
      pay_voucher: "", // 线下支付-凭证

      areaDialogBol: false, // 省市区弹框 显示/隐藏
      realDialogBol: false, // 推荐人修改弹框 显示/隐藏

      isClick: true // 是否可点击
    };
  },
  created() {
    const { id } = this.$route.params;
    $get(`/home/product/buy/${id}`).then(res => {
      this.info = res;
      this.area_name = res.user.area_name;
      this.realname = res.user.parent_data.realname;
    });
  },
  methods: {
    // 提交订单
    onSubmit(values) {
      if (this.count) {
        if (
          (this.pay_type == "3" && this.pay_voucher) ||
          this.pay_type == "0"
        ) {
          console.log("submit", values);
          if (this.isClick) {
            this.isClick = false;
            $post("/home/productOrder/submitOrder", {
              product_id: this.info.product.id, // 商品id
              nums: this.count, // 商品数量
              pay_type: this.pay_type, // 0-微信支付，3-线下支付
              pay_voucher: this.pay_voucher.url // 凭证
            })
              .then(res => {
                console.log('----提交订单成功----', res)
                if (res.contract_sign_url) {
                  // 去签订
                  localStorage.setItem("wxLoginStatus", true);
                  window.location.href = res.contract_sign_url;
                } else {
                  console.log('-----不需要签署-----', this.pay_type)
                  if (this.pay_type == "0") {
                    // 微信支付
                    console.log('-------进入微信支付接口--------')
                    $get("/home/pay", {
                      pay_type: 0, // 0-微信支付，3-线下支付
                      order_no: res.order_no // 订单编号
                    })
                      .then(res => {
                        console.log('-------微信支付接口成功--------', res)
                        $wxPay(res.pay_info).then(res => {
                          console.log("--------支付成功-----------", res);
                          // 微信支付成功 - 去订单列表;
                          this.$router.push({
                            name: "myOrder",
                            params: { page: "all" }
                          });
                        }).catch(err=> {
                          console.log("--------支付失败-----------", err);
                          // 支付失败/取消支付 - 去订单列表;
                          this.$router.push({
                            name: "myOrder",
                            params: { page: "all" }
                          });
                        });
                        this.isClick = true;
                      })
                      .catch(err => {
                        this.isClick = true;
                      });
                  } else {
                    // 线下支付成功 - 去订单列表
                    this.$router.push({
                      name: "myOrder",
                      params: { page: "all" }
                    });
                  }
                }
              })
              .catch(err => {
                this.isClick = true;
              });
          }
        } else {
          this.$toast("拍照上传支付凭证和身份证");
        }
      } else {
        this.$toast("请输入商品数量");
      }
    },

    // 选择区域
    handleArea() {
      if (this.info.user.change_area && !this.newArea_name) {
        // 可选择
        this.areaDialogBol = true;
      }
    },

    // 确认选择区域
    submitArea(e) {
      if (this.isClick) {
        this.isClick = false;
        $post("/home/setUserArea", {
          province_code: e[0].code,
          city_code: e[1].code,
          county_code: e[2].code
        })
          .then(res => {
            this.area_name = e[0].name + e[1].name + e[2].name;
            this.areaDialogBol = false;
            this.isClick = true;
          })
          .catch(err => {
            this.isClick = true;
          });
      }
    },

    // 推荐人修改弹框
    handleReal() {
      if (this.info.user.change_parent) {
        this.newRealname = "";
        this.realDialogBol = true;
      }
    },

    // 确认推荐人修改
    submitReal(e) {
      if (this.newRealname) {
        if (this.isClick) {
          this.isClick = false;
          $post("/home/changeParent", {
            parent_mobile: this.newRealname
          })
            .then(res => {
              console.log(res, "----------------");
              this.area_name = res.area_name;
              this.newArea_name = res.area_name; // 加盟商区域
              this.realname =
                res.parent_data.realname + "-" + res.parent_data.mobile; // 推荐人手机号
              this.info.user.change_parent = 0; // 确认推荐人-不可修改
              this.realDialogBol = false;
              this.isClick = true;
              this.$toast("操作成功");
            })
            .catch(err => {
              this.isClick = true;
            });
        }
      } else {
        this.$toast("请输入推荐人电话");
      }
    },

    // 上传凭证完成
    uploadFinish(e) {
      this.pay_voucher = e;
    }
  }
};
</script>

<style lang="scss" scoped>
.order-confirm {
  min-height: 100%;
  background: #f2f5fc;
  box-sizing: border-box;
  padding-bottom: 107px;

  &-title {
    padding: 14px 16px;
    font-size: 14px;
    line-height: 100%;
    color: #8e8e8e;
  }

  &-1 {
    padding: 10px 17px 15px 16px;
    background-color: #fff;
    display: flex;

    &-1 {
      width: 76px;
      height: 76px;
    }

    &-2 {
      width: 252px;
      margin-left: 14px;
      padding-top: 3px;

      .title {
        font-size: 18px;
        line-height: 100%;
      }

      .content {
        font-size: 14px;
        margin-top: 7px;
        line-height: 16px;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  &-1:after {
    display: block;
    content: "";
    clear: both;
  }

  &-2 {
    padding: 12px 15px;
    margin-top: 1px;
  }

  &-3 {
    padding: 17px 10px 17px 15px;
  }

  &-4 {
    padding: 17px 15px;
  }

  &-5 {
    width: 345px;
    margin: 40px auto 0;
    border-radius: 10px;
    background: #ffffff;
    overflow: hidden;

    .van-cell {
      padding: 15px 13px;

      .van-cell__title {
        display: flex;
        align-items: center;

        img {
          width: 24px;
        }

        span {
          font-size: 16px;
          margin-left: 6px;
          color: #000000;
        }
      }
    }
  }

  &-6 {
    width: 375px;
    height: 66px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 11px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    &-1 {
      font-size: 20px;
      color: #333333;

      span {
        color: #00a63a;
      }
    }

    &-2 {
      width: 150px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #00a63a;
      border-radius: 109px;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .van-cell__title.van-field__label {
    display: flex;
    display: none;
  }

  .uploadBox {
    width: 90%;
    margin: 30px auto;
    padding: 15px 0;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    .pay_voucher {
      width: 2.133333rem;
      height: 2.133333rem;
    }
    p {
      font-size: 14px;
      color: #999999;
    }
  }
}

.realDialogBox {
  border-radius: 10px;
  .realDialog {
    text-align: center;
    padding: 30px 15px;
    input {
      width: 230px;
      height: 50px;
      box-sizing: border-box;
      line-height: 50px;
      padding: 0 14px;
      outline: none;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #c3c3c3;
      text-align: center;
    }
    button {
      outline: none;
      margin: 40px auto 0;
      padding: 0;
      border: none;
      background-color: #00a63a;
      color: #fff;
      font-size: 18px;
      padding: 8px 30px;
      border-radius: 10px;
      text-align: center;
    }
  }
}
</style>